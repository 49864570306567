import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import { Viewer, Entity, PointGraphics, EntityDescription } from 'resium';
// import { Cartesian3 } from 'cesium';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

function Map() {
  const classes = useStyles();
  
  return (
  <div className={classes.root}>
    <Card style={{ padding: "0px", marginbottom: "500px", paddingleft: "0px" }}>
        <CardContent style={{ backgroundColor: 'black', width: '100%', height: "100%", margintop: "200px", paddingleft: "0" }}>

        </CardContent>
    </Card>
  </div>
  );
}

export default Map;