import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListIcon from '@material-ui/icons/List';
import { Viewer, Entity, PointGraphics, EntityDescription } from 'resium';
import { KmlDataSource, GeoJsonDataSource } from "resium";
import { Cartesian3 } from 'cesium';
import Modal from "react-modal";

//modal
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import swal from 'sweetalert';
import "../../src/styles.css";

Modal.setAppElement("#root");

//การทำ Modal
//https://www.newline.co/@dmitryrogozhny/how-to-display-modal-dialog-in-react-with-react-modal--dbf46cda
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

export default function Profile() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open = Boolean(anchorEl);
  const user = JSON.parse(localStorage.getItem('user'));
  const [isOpen, setIsOpen] = React.useState(false);

  //data.user = user;
  const [ReportType, setReportType] = React.useState();

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = "/login";
    //window.location.href = "/Map";
  };

  const handleSubmit = () => {

    swal("สำเร็จ", "บันทึกข้อมูลสำเร็จ", "success", {
      buttons: false,
      timer: 2000,
    })

  }

  var myRegions = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "properties": {
          "ประเภท": "ลักลอบตัดไม้",
          "รายละเอียด": "รายละเอียดการลักลอบตัดไม้",
          "ผู้รายงาน": "ร.ท.รักชาติ ยิ่งชีพ"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            104.517231, 14.509819
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "ประเภท": "ยาเสพติด",
          "รายละเอียด": "จับยาเสพติดจำนวน ...... เม็ด",
          "ผู้รายงาน": "ร.ท.รักชาติ ยิ่งชีพ"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            104.506192, 14.506515
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "ประเภท": "ยาเสพติด",
          "รายละเอียด": "จับยาเสพติดจำนวน ...... เม็ด",
          "ผู้รายงาน": "ร.ท.รักชาติ ยิ่งชีพ"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            104.468696, 14.527664
          ]
        }
      }
    ]
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ position: "absolute" }}>
        <Toolbar>
          <IconButton onClick={handleMenu2} color="inherit">
            <ListIcon></ListIcon>
          </IconButton>

          <Menu
            id="simple-menu"
            anchorE2={anchorE2}
            keepMounted
            open={Boolean(anchorE2)}
            onClose={handleClose2}
          >
            <MenuItem onClick={toggleModal}>ส่งรายงานข่าวสาร</MenuItem>
            <MenuItem onClick={handleClose2}>ส่งข้อมูลเครือข่าย</MenuItem>
            <MenuItem onClick={handleClose2}>สรุปสถิติ</MenuItem>
          </Menu>

          <Typography variant="h6" className={classes.title}>
            แผนที่
          </Typography>

          <div>
            <IconButton onClick={handleMenu} color="inherit">
              <Avatar src={user.avatar} />
            </IconButton>
            <Menu id="menu-appbar"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>ออกจากระบบ</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Card style={{ padding: "0px", marginbottom: "500px", paddingleft: "0px" }}>
        <CardContent style={{ backgroundColor: 'black', width: '100%', height: "100%", margintop: "200px", paddingleft: "0" }}>
          {/* <Avatar src={user.avatar} className={classes.large} />
          <Typography variant="h5">
          ยินดีต้อนรับ
          </Typography>
          <Typography variant="h4">
          {user.fname} {user.lname}
          </Typography> */}
          <Viewer style={{ backgroundColor: 'black', width: '100%', height: "99%", top: '65px' }} full
            //GeoJsonDataSource = {{data}}
            sceneModePicker={true}
            timeline={false}
            fullscreenButton={false}
            navigationHelpButton={false}
            navigationInstructionsInitiallyVisible={false}
            automaticallyTrackDataSourceClocks={false}
            selectionIndicator={false}
            geocoder={false}
            animation={false}
          >
            <KmlDataSource data={"./kml/UTMZone.kml"} />
            <GeoJsonDataSource data={myRegions} />
            {/* <Entity
              name="อุบลราชธานี"
              description="จังหวัดอุบล"
              position={Cartesian3.fromDegrees(104.893768, 15.149269, 100)}
              creditContainer="ทดสอบเครดิต"
            >
              <PointGraphics pixelSize={20} />
              <EntityDescription>
                <h1>Hello</h1>
              </EntityDescription>
            </Entity> */}
          </Viewer>
        </CardContent>
      </Card>

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >

        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ส่งข้อมูลข่าวสาร
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="select"
            label="ประเภทรายงาน"
            value = {ReportType}
            onChange={e => setReportType(e.target.value)}
            select>
            <MenuItem value="คน">คน</MenuItem>
            <MenuItem value="ไม้">ไม้</MenuItem>
            <MenuItem value="ยา">ยา</MenuItem>
          </TextField>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              label="ชื่อผู้รายงาน"
            //onChange={e => setUserName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="นามสกุล"
            //onChange={e => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="วันที่"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            //onChange={e => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              placeholder="ป้อนรายละเอียด"
              multiline
              rows={5}
              rowsMax={10}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="สถานที่จับกุม"
            //onChange={e => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="จำนวนผู้ต้องหา"
              type="Numberic"
            //onChange={e => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="ปริมาณของกลาง"
              type="Numberic"
            //onChange={e => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              บันทึก
            </Button>
          </form>
        </div>

      </Modal>

    </div>
  );
}